@font-face {
  font-family: Roboto;
  src: url('font/Roboto/Roboto-Regular.ttf');
}

body {
  font-family: Roboto, 'Helvetica', sans-serif;
}

@media (min-width: 461px) {
  .app-layout {
    max-width: 460px;
    margin: 0 auto;
  }
  .voucher-today {
    &__menu {
      max-width: 460px;
      margin: 0 auto;
    }
  }

  .product--form {
    max-width: 460px;
    margin: 0 auto;
  }

  .vt {
    &__header {
      max-width: 460px;
      margin: 0 auto;
      z-index: 99;
    }
  }
}

@media (max-width: 460px) {
  .voucher-today {
    &__menu {
      justify-content: space-between;
      .ant-menu-item {
        padding: 0 15px !important;
      }
      .item-menu-text {
        font-size: 8px !important;
        line-height: 12px;
      }
    }
  }
}

.app-content {
  padding-top: 131px;
  padding-bottom: 55px;
}

.hide-header {
  .app-content {
    padding-top: $align-half;
    padding-bottom: $align-base;
  }
}

.voucher-today {
  &__menu {
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding-top: 3px;
    text-align: center;
    border-top: 1px solid #f0f0f0;
    .ant-menu-item-icon {
      font-size: 26px;
    }
    .anticon {
      margin: 0 !important;
    }
    .item-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .item-menu-text {
      margin: 5px 0 3px 0;
      font-size: 12px;
      line-height: 12px;
    }
    svg {
      width: 21px;
      height: 21px;
    }
  }
}
.vt {
  &__header {
    height: auto;
    padding: 10px $align-base 0 $align-base !important;
    display: block !important;
    background-color: #fc2e6a !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    &-title {
      font-size: 26px;
      width: 100%;
      text-transform: uppercase;
      color: #ffffff;
    }
    &-user {
      line-height: 0;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}

.main-product {
  &__header {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
  }
  &__title {
    font-size: 11px;
    font-weight: bold;
    & i {
      font-size: 8px;
      color: #000000;
      font-weight: 600;
    }
  }
  &__price {
    font-size: 20px;
    font-weight: bold;
  }
}

.product-tab {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-nav-list {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    border: 1px solid $color-primary;
    width: 100%;
  }
  .ant-tabs-tab {
    width: 50%;
    text-align: center;
    justify-content: center;
    padding: 6px 0;
  }
  .ant-tabs-tab-btn {
    font-weight: normal;
    text-shadow: none;
    font-size: 16px;
  }
  .ant-tabs-tab-active {
    background-color: $color-primary;
    .ant-tabs-tab-btn {
      text-shadow: none;
      color: #ffffff;
      font-weight: normal;
    }
  }
  .ant-tabs-nav-operations {
    display: none;
  }
}

.voucher {
  &--wrapper {
    overflow: hidden;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    padding: 8px;
    background-color: #f5f5f5;

    .ant-btn {
      font-size: 12px;
      padding: 3px 8px;
      height: auto;
      float: right;
      font-weight: normal;
    }
  }
  &__title {
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 13px;
  }
  &__expired {
    font-size: 12px;
  }
}

.product {
  &--wrapper {
    overflow: hidden;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    padding: 10px 8px;
    background-color: #f5f5f5;
  }
  &__extra {
    font-weight: bolder;
    font-size: 14px;
    & strong {
      font-size: 16px;
    }
  }
  &__price {
    color: #1890ff;
  }
  &__discount {
    color: #f5222d;
  }
  &__sold {
    color: #52c41a;
  }
}

.user {
  h4 {
    font-size: 18px;
    color: #fc2e6a;
    font-weight: bold;
    letter-spacing: 3px;
  }
  .ant-row {
    margin-bottom: 8px;
  }
  & span {
    font-size: 16px;
  }
  &__claim {
    font-size: 18px;
    &--approve {
      color: #3644c1;
    }
    &--waiting {
      color: #d48806;
    }
    &--order {
      color: #3644c1;
    }
  }
  &--claim {
    padding-bottom: $align-base;
  }
  &--lucky {
    &-money {
      padding: $align-base 0;
      border-top: 1px solid #908888;
    }
  }
  &--order {
    padding-top: $align-base;
    padding-bottom: $align-base;
    border-top: 1px solid #e6e6e6;
  }
  &__text {
    float: right;
    font-size: 18px;
    color: #3644c1;
  }
}

.fb-login-button {
  background-color: #1877f2;
  border-radius: 6px;
  border: 1px solid #1877f2;
  color: #ffffff;
  padding: 5px 20px;
  font-size: 16px;
}

.card--promotion {
  padding-bottom: 10px;
  position: relative;

  & .ant-card-body {
    padding: $align-base;
    & > div {
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 180px;
      transition: all linear 0.2s;
    }
  }

  & .ant-card-head-title {
    padding: 8px 0;
  }

  &.active {
    & .ant-card-body {
      & > div {
        height: auto;
      }
    }

    & .card--promotion__more {
      visibility: hidden;
      &::after {
        content: 'Thu gọn';
        visibility: visible;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }

  &__more {
    position: absolute;
    right: 10px;
    text-align: right;
    width: 100%;
    height: auto !important;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &.show {
    text-overflow: initial;
  }
}

.card--order {
  font-size: 16px;
  background-color: #f0f0f0;
  & .ant-card-body {
    padding: $align-base;
  }
}

.product {
  overflow: hidden;
  border: 1px solid #fce4ec;
  border-radius: 6px;
  margin: 6px;

  &--scroller {
    margin-top: 58px;
    &.margin-smaller {
      margin-top: $align-half !important;
    }
  }

  &--form {
    margin-bottom: 0 !important;
    position: fixed;
    top: -20px;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;
    padding: 25px 0 15px;
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &--slide {
    .slick-slide .ant-image {
      display: flex;
      justify-content: center;
      margin: 0 5px;
    }
    .slick-slide img {
      height: 165px;
    }
  }
  &--name {
    padding: 0 18px;
    & h3 {
      font-weight: bolder;
    }
  }
  &--description {
    padding: 0 $align-base;
    font-size: 14px;
  }
  &--buy {
    padding: 0 $align-base;
  }
}

.order-modal {
  margin: 0;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  top: 15vh;
  transform-origin: bottom;
  & .ant-modal-content {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-height: 85vh;
    overflow: auto;
  }
  & .ant-modal-body {
    padding: 20px 15px 0 15px;
  }

  & .ant-modal-close {
    display: none;
  }
}

.order-pagination {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-blue {
  color: #1890ff;
}

.listDeal {
  overflow: hidden;
  border: 1px solid #cae0f6;
  border-radius: 6px;
  margin: 6px;

  &-code {
    background: radial-gradient(
      120.7% 310.42% at 5.7% 13.51%,
      #8833dd 0%,
      #bb5555 100%
    );
  }

  &-refund {
    background: radial-gradient(
      120.7% 310.42% at 5.7% 13.51%,
      #126715 0%,
      #a09c2c 100%
    );
  }

  &--discount {
    width: 79px;
    height: 39px;
    position: absolute;
    left: 10px;
    bottom: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: #fff;
      text-transform: uppercase;
      margin: 0;
    }

    p:first-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.25px;
    }

    p:last-child {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
    }
  }

  &--name {
    padding: 0 10px;
    margin-top: 5px;
    font-size: 11px;
    line-height: 15px;

    & h3 {
      font-weight: bolder;
    }
  }

  &--description {
    color: #8833dd;
    padding: 0 10px;
    margin-top: -8px;
    font-size: 10px;
    line-height: 13px;
  }

  &--buy {
    padding: 0 6px;
    margin-bottom: 8px;
    margin-top: -4px;

    .ant-btn {
      border: double 1px transparent;
      border-radius: 3px;
      background-image: linear-gradient(white, white),
        linear-gradient(to right, #bf9ae4, #ea9696);
      background-origin: border-box;
      background-clip: content-box, border-box;

      span {
        background: -webkit-linear-gradient(
          0,
          #ce204a 22.66%,
          rgba(136, 51, 221, 0.63) 50%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 13px;
        line-height: 16px;
      }
    }

    .ant-btn-sm {
      padding: 0;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

.dealDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;

  &-line {
    width: 100%;
    height: 10px;
    background-color: #eee;
    padding: 0 1px;
  }

  &--slider {
    width: 100%;
    margin-bottom: 6px;
    padding: 0 17px;

    &-slide {
      margin: 20px 0 10px 0;

      .ant-image {
        width: 100% !important;
      }

      button {
        opacity: 1 !important;
        width: 20px !important;
        height: 4px !important;
      }

      .slick-active {
        width: 30px;

        button {
          background-color: #fc2e6a !important;
          width: 27px !important;
        }
      }
    }

    &-title {
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      color: #1f2023;
    }

    &-price {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      margin: 9px 0 0 0;
    }

    &-sale {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      margin: 5px 0 0 0;
      background: linear-gradient(90.53deg, #b50bee 0.09%, #e33333 76.43%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &-link {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      margin: 5px 0 0 0;
    }

    &-contact {
      display: flex;
      align-items: center;

      &-btn {
        padding: 3px 10px;
        margin: 6px 8px 3px -1px;
        font-size: 12px;
        font-weight: 400;
        background-color: #1b74e4;
        color: #fff;
        border-radius: 3px;
      }
    }
  }

  &--step {
    width: 100%;
    padding: 0 17px;
    margin: 8px 0;

    &-title {
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      color: #383636;
      margin: 0;
      padding-bottom: 8px;
      border-bottom: 1px solid #dedede;
    }

    &-confirm {
      margin-top: 6px;

      &-register {
        padding: 5px 20px;
        font-size: 14px;
        font-weight: 400;
        background-color: #fc2e6a;
        color: #fff;
        border-radius: 3px;
      }

      .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-icon {
        background: linear-gradient(
          180deg,
          #8427ce 0%,
          rgba(44, 142, 197, 0.88) 100%
        );
        // border-color: #fff;
      }

      .ant-steps-item-icon {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-steps-icon {
          font-size: 18px;
          margin-top: 3px;
        }
      }

      .ant-steps-item-title {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #5f5d5d;
      }

      .ant-upload-list-picture-container {
        margin-top: -20px;
      }

      .ant-radio-group {
        margin-top: 5px;
      }

      .ant-input {
        margin: 6px 0 3px 0;
      }

      .ant-image {
        display: block !important;
      }

      .ant-steps-item-description {
        padding: 0 !important;
      }

      .ant-image-mask {
        width: 150px !important;
        height: 120px !important;
      }

      // .ant-upload-list-item-card-actions-btn {
      //   display: none;
      // }
    }
  }
}

.orderDetail {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  border-radius: 5px;

  &--list {
    padding: 10px 9px 0px 8px;

    &-title {
      font-size: 22px;
      color: #fc2e6a;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &-table {
      width: 100%;
    }

    th {
      background-color: #e6e6e6 !important;
      padding: 5px !important;
    }

    th:not(:nth-last-child(2)) {
      text-align: center;
    }

    td {
      padding: 1px 0 5px 0 !important;
    }

    td:not(:nth-last-child(2)) {
      text-align: center;
    }
  }
}

.ant-modal-body {
  padding: 10px 20px 20px 20px;
}
